@use "../../sass/abstract/" as *;
.sign-bg {
  display: none !important;
}
.signin {
  width: 100% !important;
  height: 100vh !important;
  overflow: hidden;
  background-color: #e75b5e;
  position: relative;
  margin: 0;
}

.singup-right-section {
  border-top-left-radius: 35px !important;
  border-bottom-left-radius: 35px !important;
  height: 100vh !important;
  background: linear-gradient(144.66deg, #e9e9e9 -8.36%, #ffffff 44.59%);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white !important;
  display: flex;
  @include xsBreakPoint {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    overflow-y: auto;
  }
  @include smBreakPoint {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    overflow-y: auto;
  }
}
.left {
  background-color: #e75b5e;
  width: 50% !important;
}

.logo {
  position: absolute;
  top: 10%;
  left: 5%;
  .logo-womeyn {
    width: 115%;
    height: auto;
  }
}

.circle {
  position: absolute;
  top: 30%;
  left: 10%;
  @include mdBreakPoint {
    top: 40% !important;
  }
  .circles {
    width: 90%;
    @include mdBreakPoint {
      width: 70% !important;
      top: 20% !important;
    }
  }
}
.sellers {
  position: absolute;
  bottom: 10%;
  left: 10%;
}

.we {
  font-size: 38px !important;
  font-weight: bold;
  color: #ff9c9e;
  @include mdBreakPoint {
    font-size: 30px !important;
  }
}
.seller {
  font-size: 38px !important;
  font-weight: bold;
  color: #f3f3f4;
  @include mdBreakPoint {
    font-size: 30px !important;
  }
}

.auth-card {
  padding: 0px !important;
  @include xsBreakPoint {
    padding: 10% !important;
  }
  @include smBreakPoint {
    padding: 13% !important;
  }
}

.logo-image {
  display: flex !important;
  justify-content: flex-end !important;
  height: 94% !important;
  padding-top: 7% !important;
}
