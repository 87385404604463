@use "../../sass/abstract/" as *;

.image-style {
  width: 150px;
  height: 150px;
}

.title {
  &:hover {
    color: #e95a5a;
  }
}
