@use "../../../sass/abstract/" as *;

.product-name {
  width: 200px !important;
}

.card12 {
  border: 1px solid #f2f2f2;
  width: 48% !important;
  @include lgBreakPoint {
    width: 48% !important;
  }
  @include xlBreakPoint {
    width: 48% !important;
  }
}

.card23 {
  border: 1px solid #f2f2f2;
  width: 100% !important;
  margin: 10px 0px;
  padding: 10px;
  @include lgBreakPoint {
    width: 100% !important;
  }
  @include xlBreakPoint {
    width: 100% !important;
  }
}
