@use "../../../../../../sass/abstract/" as *;

.productplans {
    color: black !important;
}
.AddBlog {
    width: auto;
    padding: 10px 30px;
    border-radius: 6px;
    color: white;
    background: #e95a5a;
    border: none;
    outline: none;
}

.productplaniconssections {
    width: 100%;
    display: flex;
    gap: 30px;
    padding-top: 2%;
    .iconedits {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}

.planname {
    font-weight: 600;
    font-size:1.4rem;
    color:#e95a5a;
}


.demo-editor
{
    border:1px solid red;
    height:400px !important;
}