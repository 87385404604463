.sidebar-base.left-bordered {
    .navbar-nav{
        &:not(.sub-nav) {
            >.nav-item {
                &.active, &:not(.static-item) {
                    position: relative;
                    &::before {
                        left: unset !important;
                        right: 0;
                        border-top-left-radius: $border-radius;
                        border-bottom-left-radius: $border-radius;
                    }
                }
            }
        }
    }
    &.sidebar-mini{
        .navbar-nav{
            &:not(.sub-nav) {
                >.nav-item {
                    &.active, &:not(.static-item) {
                        position: relative;
                        &::before {
                            right: -35%;
                        }
                    }
                }
            }
        }
    }
}
