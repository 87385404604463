.col-auto { grid-column: auto; }
.col-span-full { grid-column: 1 / -1; }
.col-start-auto { grid-column-start: auto; }
.col-end-auto { grid-column-end: auto; }
.grid-rows-none { grid-template-rows: none; }
.gap{ gap: $grid-gutter-width; }
.gap-card {gap: $card-spacer-x;}
.grid-flow-col { grid-auto-flow: column; }
.gap-column-3 { row-gap: 3rem; }
// Grid Column Loop
@for $i from 1 through $grid-columns {
  .grid-cols-#{$i} { grid-template-columns: repeat($i, minmax(0, 1fr)); }
  .col-span-#{$i} { grid-column:  span $i / span $i; }
  .col-start-#{$i} { grid-column-start: $i; }
  .col-end-#{$i} { grid-column-end: $i; }
}
