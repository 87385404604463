@use "sass:math";

@mixin avatar ($value, $radius: 0) {
    $value_rem: math.div($value,16);
    height: $value_rem + rem; 
    width: $value_rem + rem; 
    min-width: $value_rem + rem;
    text-align: center;
    line-height: $value_rem + rem;
    @if $enable-rounded == true {
        border-radius: $border-radius;
    }
}