@use "../../../sass/abstract/" as *;

.header {
  display: flex;
  justify-content: space-between;
}

.content-margin {
  margin-top: 25px;
}

.background {
  background-color: #ff9c9e5e !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  // display: none;
  // -webkit-appearance: none;
  filter: invert(1);
}

//coupon list---------------------------------

.coupon-tabsize {
  @include mdBreakPoint {
    width: 50% !important;
  }
  @include smBreakPoint {
    width: 50% !important;
  }
  .couponlist-full-container {
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    margin-top: 5%;
    background: #fff;
    .couponlist-center-container {
      height: 140px;
      @include xlBreakPoint {
        height: 130px;
      }
      @include lgBreakPoint {
        height: 130px;
      }
      @include mdBreakPoint {
        height: 140px;
      }
      @include smBreakPoint {
        height: 140px;
      }
      .couponheaderlist {
        display: flex;
        justify-content: space-between;
        margin-left: 3%;
        .coupon-title-text {
          margin-top: 5%;
          font-size: 18px;
          font-weight: 600;
          color: #e95a5a;
          @include mdBreakPoint {
            margin-top: 2%;
          }
        }
      }
      .couponcode-text {
        font-size: 16px;
        font-weight: 400;
        color: black;
        margin-top: 5%;
        margin-left: 3%;
        color: #e95a5a;
        @include mdBreakPoint {
          margin-top: 2%;
        }
      }
      .couponcode-desc {
        font-size: 13px;
        font-weight: 400;
        color: black;
        margin-top: 1%;
        margin-left: 3%;
      }
    }

    .coupon-btm-container {
      display: flex;
      justify-content: space-between;
      padding: 3%;
      border-top: 1px solid #c0c0c0;
      margin-top: 5%;
      .coupon-edit-btn {
        background-color: white;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
        padding: 1%;
        padding-right: 5%;
        padding-left: 5%;
      }
      .coupon-dlte-btn {
        background-color: white;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
        padding: 1%;
        padding-right: 5%;
        padding-left: 5%;
      }
      .coupon-switch-btn {
        height: 20px;
        width: 40px;
      }
    }
  }
}
