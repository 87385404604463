@use '../abstract' as *;

.customised-switch {
    position: relative;
    display: inline-block;
    width: 43.64px !important;
    height: 20px !important;
}

.customised-switch input {
    opacity: 0;
    width: 0 !important;
    height: 0 !important;
    position: relative;
}

.customised-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    @include themed(){
        background-color: t($background);
    }
}

.customised-slider:before {
    position: absolute;
    content: "";
    height: 18px !important;
    width: 18px !important;
    left: 2px;
    bottom: 1px;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    @include themed(){
        background-color: t($secondary);
    }
}

input:checked + .customised-slider {
    @include themed(){
        background-color: t($primary) !important;
    }
}

input:focus + .customised-slider {
    @include themed(){
        box-shadow: 0 0 1px t($secondary) !important;
    }
}

input:checked + .customised-slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
}

/* Rounded sliders */
.customised-slider.customised-round {
    border-radius: 34px;
}

.customised-slider.customised-round:before {
    border-radius: 50%;
}