@use '../../../../../../sass/abstract/' as *;

.inside-cardss
{
    width: 100%;
    display: flex;
    gap:40px;
    .leftblog
    {
        width: 50%;
    }
    .blogiconsright
    {
        display: flex;
        gap:20px;
        
        width: 50%;
        .blogsicons
        {
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
   
}