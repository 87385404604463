@use "../abstract/" as *;

head,
tbody,
tfoot,
tr,
td th {
  white-space: normal !important;
}


.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  --bs-table-accent-bg: white !important;
  // color: var(--bs-table-striped-color);
  cursor: pointer !important;
}

.table-striped > tbody > tr:nth-of-type(2n):hover {
  --bs-table-accent-bg: blue !important;
  // color: var(--bs-table-striped-color);
  cursor: pointer !important;
  // width: 100% !important;

}