.flatpickr-months{
    .flatpickr-prev-month{
        >svg{
            fill: $card-bg;
            color: $card-bg;
            &:hover{
                fill: var(--bs-primary-shade-40) !important;
                color: var(--bs-primary-shade-40) !important;
            }
        }
    }
}