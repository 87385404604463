.footer {
    font-size: $footer-font-size;
    background: $white;
    .footer-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.5rem calc(var(--bs-gutter-x, 1rem) * 2);
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
    }
    &.sticky{
        position: sticky;
        bottom: 0;
    }
    &.glass{
        background-color: rgba($white, .1);
        border-top: 1px solid $white;
        backdrop-filter: blur(10px);
    }
}