.select2-container--default .select2-selection--single .select2-selection__rendered {
    width: unset;
}

.select2-container .select2-container--focus .select2-selection--multiple {
    border: 1px solid var(--#{$variable-prefix}primary);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    border-right: 0px;
    color: $white;
    background-color: var(--#{$variable-prefix}primary);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    color: $white;  
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: $white;
    height: 1.5rem;
    width: 1rem;
    border-right: 0px;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable,
.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: var(--#{$variable-prefix}primary);
    color: $white;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow{
    height: 30px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
    border: 1px solid var(--#{$variable-prefix}secondary); 
}
.select2-container--classic .select2-selection--single:focus {
    border:1px solid var(--#{$variable-prefix}primary)
}


//recent changes

.select2-container--default .select2-results__option--selected,
.select2-container--classic .select2-results__option--selected{
    background: var(--#{$variable-prefix}primary-shade-40);
    color: var(--#{$variable-prefix}white);
}
.select2-container--default .select2-selection--single .select2-selection__arrow{
    right: 1rem;
}
.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--multiple{
    padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
    color: $input-color;
    line-height: $input-line-height;
}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple{
    color: $input-color;
    background-color: $input-bg;
    border-radius: $input-border-radius;
    min-height: 2.625rem;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;
}
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__clear,
.select2-container--classic .select2-selection--single .select2-selection__arrow{
    height: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--#{$variable-prefix}primary);
    border: 1px solid var(--#{$variable-prefix}primary);
    margin: unset;
    margin-right: .5rem;
}
.select2-container--classic .select2-selection--single{
    min-height: 2.625rem;
}
.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid var(--#{$variable-prefix}primary-tint-40);
    transition: $form-select-transition;
}
.select2-automatic-tokenizer +.select2-container{
    display: inline;
}