@import "../hope-ui-design-system/variable";

.table {
  .flex-container {
    display: flex;
    flex-wrap: wrap;

    .btn {
      padding: 0.5rem;
      margin: 0.1rem;
      color: #6c6d6e;
    }

    .warning {
      background-color: $warning !important;
      color: #000000;
    }
  }

  .p-bold {
    color: $form-label-color;
    font-weight: 500;
  }

  .yellow-text {
    color: #ffc107;
  }

  .green-text {
    color: #198754;
  }

  .blue-text {
    color: #0dcaf0;
  }

  .red-text {
    color: #dc3545;
  }

  .status-box {
    width: 24px;
    height: 24px;
    margin-left: 0.5rem;
    background: #ffc107;
    border-radius: 8px;
  }
}

.user-card-content {
  color: $form-label-color;

  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }

  .permission {
    p {
      margin: auto;
    }

    span {
      margin: auto 0.25rem;
      padding: 0.1rem 1rem;
      background: #e0e0e0;
      border-radius: 24px;
    }
  }

  .contact {
    margin-top: 0.5rem;

    p {
      margin: auto 0;
    }

    span {
      margin: auto 0.25rem;
      color: #3a57e8;
    }
  }
}

.action-link {
  color: #3a57e8;
  font-weight: 400;
  font-style: normal;
}

.title-with-icon {
  display: flex;

  width: 250px;

  svg,
  h3 {
    margin: auto;
  }
}

.card {
  // padding: 2rem;

  .form-control {
    background-color: #f9f9f9;
  }

  .form-label,
  .list-group-item {
    color: $form-label-color;
  }

  .field-container {
    width: 50%;

    margin-bottom: 2rem;
  }

  .margin-left-button {
    margin-left: 2rem;
  }
}
