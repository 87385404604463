@use "../../../../sass/abstract/" as *;

.numbercontacts {
    // background-color: red;
    width: 100%;
    .inputnumbercontact {
        width: 100%;
    }
    .numbercontactss {
        background-color: #f9f9f9;
        align-items: center;
        justify-content: center;
        display: flex;
        padding: 0px 15px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        font-weight: 500;
    }
}
