@use '../../../../../../../../sass/abstract/' as *;

.buttonsections {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-top: 2%;
    margin-bottom: 2%;
    .savebutton {
        width: 100%;
        padding: 7px 33px;
        border-radius: 5px;
        border: none;
        outline: none;
        color: white;
        background: #e95a5a;
    }
    .cancelbutton {
        width: 100%;
        padding: 7px 33px;
        border-radius: 5px;
        border: none;
        outline: none;
        border: 1px solid #e95a5a;
        background: white;
        color: #e95a5a;
    }
}

.main-camera-sections {
    border: 2px dashed #e95a5a;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .inside-camera-section-upload {
        width: 100px;
        height: 100px;
        background-color: rgba(233, 90, 90, 0.2);
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
.boximage {
    position: relative;

    .blogbannerimage {
        width: 150px;
        height: 150px;
        border-radius: 10px;
        position: relative;
    }
    .editblogimageedit {
        width: 100%;
        height: 100%;
        position: absolute;
        top: -3%;
        right: -1%;
        .editblogimage {
            width: 30px;
            height: 30px;
        }
    }
}
