@use "../../../../../sass/abstract/" as *;

.blogbox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .AddBlog {
        width: 100%;
        padding: 10px 30px;
        border-radius: 6px;
        color: white;
        background: #e95a5a;
        border: none;
        outline: none;
    }
}
.cards {
    width: 100%;
    // border: 2px solid red;
    // align-items: center;
    // justify-content: center;
    display: flex;
    padding-left: 2%;
    gap: 1.5%;

    .card {
        width: 32.3% !important;
        // border: 3px solid blue;
        // background: #f9f9f9;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 8px;
        margin-top: 1%;
        padding: 30px 20px !important;
        border-radius: 20px;

        .inside-cards {
            width: 100%;
            display: flex;
            gap: 20px;
            padding-bottom: 5%;
            border-bottom: 1px solid #9f9f9f;
            .imageblogs {
                width: 100px;
                height: 100px;
                border-radius: 10px;
            }
        }
        .icons {
            width: 100%;
            display: flex;
            gap: 20px;
            margin-top: 3%;
            .blogsicon {
                width: 30px;
                height: 30px;
                cursor: pointer;
            }
        }
    }
}
