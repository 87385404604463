@use "../../../sass/abstract/" as *;

.svg-style {
  font-size: 24px;
  color: #e95a5a;
  cursor: pointer;
}

.cursor-style {
  cursor: pointer;
}
