@use "../../../sass/abstract/" as *;

.login-content {
  width: 100% !important;
  height: 100vh !important;
  overflow: hidden !important;
}
.button-forgetpassword {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-button {
  width: 30% !important;
  background-color: #e95a5a !important;
}

.loadMore-div {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadMore-button {
  width: 25% !important;
  background-color: #e95a5a !important;
}

.modal-bodys {
  padding-top: 10% !important;
}

.logo-image {
  display: flex !important;
  justify-content: flex-end !important;
  height: 94% !important;
  padding-top: 7% !important;
}
