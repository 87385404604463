@each $color in $colors-name {
    .text-#{$color}{
        color: var(--#{$variable-prefix}#{$color}) !important;
    }
    .bg-#{$color}{
        background-color: var(--#{$variable-prefix}#{$color}) !important;
    }
    .bg-soft-#{$color}{
        color: var(--#{$variable-prefix}#{$color});
        background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .1) !important;
    }
    a, button{
        &.bg-soft-#{$color}{
            color: var(--#{$variable-prefix}#{$color});
            background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .1) !important;
            &:hover, &:focus{
                color: var(--#{$variable-prefix}#{$color}-shade-20);
                background-color: rgba(var(--#{$variable-prefix}#{$color}-rgb), .2) !important;
            }
        }
    }
}